<template>
  <!-- 

    TODO: Not Found
https://www.etusersgroup.org/wp-content/uploads/etug-restricted/2016meeting/019%20-%20In-Process%20Classification%20-%20Australia%20Plant%20-%20Oct%202016%20Bob%20Ford.pdf
https://www.etusersgroup.org/wp-content/uploads/etug-restricted/2016meeting/023%20-%20In-Process%20and%20Machine%20Energies%20-%20MBOM%20Impact%20&%20ABL%20ESD%20-%20Clint%20Guymon.pdf

 -->
  <div
    class="meeting px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full pb-30">
        <TitleComponent
          class="py-8"
          title="October 2016 Meeting"
          size="large"
        />
        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full md:w-1/2 pr-3">
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              ATTENDEES
            </h2>
            <p class="font-bold mb-4">
              Below is a list of some of the entities represented at the
              meeting:
            </p>
            <ul class="list-disc p-light ml-10">
              <li>Aerospace Corporation</li>
              <li>BAE Systems</li>
              <li>
                BATF / National Center for Explosives Training &amp; Research
              </li>
              <li>Canadian Explosives Research Laboratory</li>
              <li>Esterline</li>
              <li>Energetic Materials Research and Testing Center</li>
              <li>Federal Bureau of Investigation (FBI)</li>
              <li>
                Federal Institute for Materials Research and Testing (BAM)
              </li>
              <li>Los Alamos National Laboratory</li>
              <li>Naval Research Laboratory</li>
              <li>
                Orbital ATK
                <ul class="list-disc p-light ml-8">
                  <li>ABL</li>
                  <li>Corporate</li>
                  <li>Bacchus</li>
                  <li>Promontory</li>
                </ul>
              </li>
              <li>Safety Management Services, Inc.</li>
              <li>Tooele Army Depot</li>
              <li>Sandia National Laboratories</li>
              <li>Signature Science, LLC</li>
              <li>
                TNO – Netherlands Organization for Applied Scientific Research
              </li>
              <li>U.S. Army RDECOM – ARDEC – Picatinny Arsenal</li>
              <li>Vista Outdoor</li>
            </ul>
            <img src="@/assets/meeting/2016-min.jpg" />
          </div>
          <div class="w-full md:w-1/2 pl-3">
            <div class="mb-6">
              <h2
                class="mb-6"
                style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
              >
                MEETING PROCEEDINGS
              </h2>
              <div
                style="border-color:#e7e4e2;border-width:0px;background-color:#ffffff;"
                class="px-6 py-4 mb-4 fusion-panel panel-default fusion-toggle-no-divider fusion-toggle-boxed-mode"
                role="tabpanel"
              >
                <div class="panel-heading pl-6">
                  <h4
                    class="panel-title toggle"
                    style="position:relative;"
                    data-fontsize="16"
                    data-lineheight="22"
                  >
                    <a
                      @click="showContent = showContent === 1 ? false : 1"
                      :class="{ open: showContent === 1 }"
                      style="cursor:pointer;"
                      aria-expanded="false"
                      aria-selected="false"
                      aria-controls="811950d795ab93cb4"
                      role="tab"
                      data-toggle="collapse"
                    >
                      <span
                        class="fusion-toggle-icon-wrapper"
                        aria-hidden="true"
                      >
                        <i class="fa-fusion-box"></i>
                      </span>
                      <span
                        class="fusion-toggle-heading font-bold"
                        style="color:#ef9a3d;"
                        >2016 Meeting Proceedings</span
                      >
                    </a>
                  </h4>
                </div>
                <transition name="fade">
                  <div
                    id="811950d795ab93cb4"
                    class="panel-collapse collapse"
                    v-if="showContent === 1"
                  >
                    <div
                      class="flex flex-col panel-body toggle-content fusion-clearfix pt-6"
                    >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F001%20-%20Welcome.pdf?alt=media&token=7d9d67c4-6884-4c1b-b699-a65e3dbf52a1"
                        >001 – Opening Remarks – Bob Ford</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F001A%20-%20ETUG%20Charter%20and%20Standards%20-%20Bob%20Ford.pdf?alt=media&token=f17f3367-0738-4148-a07b-da1a29bc968c"
                        >001A – ETUG Charter and Standards – Bob Ford</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F002%20-%20ISO%2017025%20Certification%20-%20Ben%20Ostrow.pdf?alt=media&token=b3162f62-09b3-4286-a09c-f4ec7bbd801e"
                        >002a – ISO 17025 Certification – Ben Ostrow</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F002b%20-%20Presentation_ETUsers_Oct2016_draft.pdf?alt=media&token=e7da0046-d552-4697-b5fa-ff2bb2e21ab7"
                        >002b – ISO 17025 Discussion – Sandra Goldthorp</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F003%20-%20ISO%20Certification%20Discussion.pdf?alt=media&token=e5b50bb4-31c7-41d5-a388-1e51939f2dde"
                        >002c – ISO Certification Discussion – Jared Teter</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F004%20-%20Review%20of%20Round%20Robin%20Objectives%20and%20Approach%20-%20Clint%20Guymon.pdf?alt=media&token=e97f8a30-7726-4b17-bf4f-0974cc9854a0"
                        >004 – Review of Round Robin Objectives and Approach –
                        Clint Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F005a%20-%20ETUG_2016_Parrish.pdf?alt=media&token=56fd5780-03e1-48f7-970c-ea0eb8be93cd"
                        >005a – NRL HMX Round Robin Results – Damon Parrish</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F005b%20-%20Round%20Robin%20Results%20-%20Clint%20Guymon.pdf?alt=media&token=7da7f1dc-c4b1-4fbe-bde7-f4d9b9675130"
                        >005b – SMS HMX Round Robin Results – Clint Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F005c%20-%20Round%20Robin%20Presentation%20TSL.pdf?alt=media&token=5cb03c7d-a723-4afa-b7e5-6e3c9254e96a"
                        >005c – TSL HMX Round Robin Results – Ben Ostrow</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F005d%20-%20Orbital%20ATK%20Round%20Robin%20Test%20Results%202016.pdf?alt=media&token=195115aa-8e43-487e-8fa5-1be56af844ba"
                        >005d – Orbital ATK Bacchus HMX Round Robin Results –
                        Jacob Plovanic</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F006%20-%20Round%20Robin%20Results%20Summary%20-%20Clint%20Guymon.pdf?alt=media&token=b435f772-0f1f-41f3-b07a-87175fa794d7"
                        >006 – HMX Round Robin Results Summary – Clint Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F006a%20-%20etug_2016-vonOertzen.pdf?alt=media&token=5b1bb82a-95da-44f8-9595-5a9d099ca337"
                        >06a – Considerations on Energy Deposition with the BAM
                        Fallhammer Impact Machine – Alexander vonOertzen</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F007%20-%202017%20Round%20Robin%20BAM%20Friction%20-%20Clint%20Guymon.pdf?alt=media&token=f47bc144-76b4-49fc-b3ad-6d70d55c2cde"
                        >006b – 2017 Round Robin BAM Friction Outline – Clint
                        Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F009%20-%20SMS_ETUsersGroupMtg_2016_Final.pdf?alt=media&token=81d5afbd-df7a-4005-8103-e12f1b5ffe3e"
                        >006c – Interlaboratory Sensitivity Results Comparison –
                        Ian Giles</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F013%20-%20SBAT-VTS-Taliani%20-%20Ryan%20Dittmar.pdf?alt=media&token=642b91ad-63c6-430e-89bf-35d8756f9ec3"
                        >013 – SBAT-VTS-Taliani – Ryan Dittmar</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F014%20-%20Shroud%20project%2B%20schlieren%20-%20Jon%20Canino.pdf?alt=media&token=8b79704e-62d6-4396-ad73-4105843c2448"
                        >014 – Gas Collection Enhancement and Schlieren Imaging
                        – Jon Canino</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F015%20-%20ABL%20ESD%20of%20MTV%20powders_final.pdf?alt=media&token=020df5b6-2310-4257-a556-55f3eb824366"
                        >015 – ABL ESD of MTV powders – Yetta Eagleman</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F016%20-%20Parametric%20Study%20of%20the%20ABL%20ESD%20Machine%20-%20Clint%20Guymon.pdf?alt=media&token=0fd96d5c-be1b-42a6-a7f9-6e4553c07366"
                        >016 – Parametric Study of the ABL ESD Machine – Clint
                        Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F018%20-%20In-Process%20Classification%20Protocol%20-%20Kirt%20Sasser.pdf?alt=media&token=1a4b7ee7-4748-4d09-8bc3-ab3889d131e3"
                        >018 – In-Process Classification Protocol – Kirt
                        Sasser</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F019%20-%20In-Process%20Classification%20-%20Australia%20Plant%20-%20%20Oct%202016%20Bob%20Ford.pdf?alt=media&token=a2db1dee-e7a9-4b13-86a4-952b0362bb05"
                        >019 – In-Process Classification – Australia Plant – Bob
                        Ford</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F021%20-%20Application_of_Sensitivity_Test_Results_to_Process_Risk_Assessment_2.pdf?alt=media&token=8693a046-3fd9-4840-88b8-5372631f114b"
                        >021 – Application of Sensitivity Test Results to
                        Process Risk Assessment – Travis Bodrero</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F022%20-%20Critical%20Dimension%20Test%20-%20Chucky%20Williams.pdf?alt=media&token=6dd1fa3b-f8f8-4c15-9e52-a7f80a177562"
                        >022 – Critical Dimension Test – Chucky Williams</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F023%20-%20In-Process%20and%20Machine%20Energies%20-%20MBOM%20Impact%20%20%26%20ABL%20ESD%20%20-%20Clint%20Guymon.pdf?alt=media&token=8fd664fb-f5b4-4470-9a25-eb1fd7ea9cb6"
                        >023 – In-Process and Machine Energies – MBOM Impact
                        &amp; ABL ESD – Clint Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F024%20-%20Case%20Study%20of%20SBAT%20vs.%20Slow%20Cook-off%20Thermal%20Characterization%20-%20Kirt%20Sasser.pdf?alt=media&token=0fea481d-31e1-4d18-95c4-5ea8b77743fd"
                        >024 – Case Study of SBAT vs. Slow Cook-off Thermal
                        Characterization – Kirt Sasser</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F025%20-%20Blast%20Resistant%20Oven%20-%20Roger%20Hale%20and%20Kirt%20Sasser.pdf?alt=media&token=4955750d-d717-4377-83c8-91cd53d97efc"
                        >025 – Blast Resistant Oven – Roger Hale</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F026%20-%20Small%20Heat%20Plate%20-%20Clint%20Guymon.pdf?alt=media&token=9c695e8f-48e2-4fde-bd47-cf5cf70b0f5b"
                        >026 – Small Heat Plate – Clint Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F028%20-%20Calibration%20Fluid%20Presentation%20-%20Kirt%20Sasser.pdf?alt=media&token=b3a62ddb-5fe8-48c6-af4c-b626c2bf97d7"
                        >028 – Koenen Calibration Fluid Candidate Results – Kirt
                        Sasser</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F029%20-%20Physics%20of%20Explosives%20Initiation%20-%20Jason%20Phillips.pptx?alt=media&token=abaacfac-af55-4aa9-ad07-1853e120156a"
                        >029 – Physics of Explosives Initiation – Jason
                        Phillips</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F030%20-%20History.pdf?alt=media&token=9b623019-dc72-409e-a82f-02c19662e5d8"
                        >030 – History of Detonation Theory – Boyd Hill</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2016%2F031%20-%20DOT%20SP-8451%20-%20Kirt%20Sasser.pdf?alt=media&token=e5e64545-7bd3-4316-a2b6-8797412a9145"
                        >031 – DOT SP-8451 Test Results – Kirt Sasser</a
                      >
                    </div>
                  </div>
                </transition>
              </div>
              <p class="p-light">
                These files are for participants of the ET Users Group. As such
                please consider the information as ‘approved for internal use
                only. Feel free to distribute this information within your
                company or group as needed. If individuals or organizations
                outside your company or group desire access to this information,
                please
                <router-link class="link" to="/contact-us"
                  >Contact Us</router-link
                >.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss">
.meeting {
  .fusion-button.button-1 {
    border-radius: 0px;
  }

  .panel-title a .fa-fusion-box:before {
    position: absolute;
    left: -2rem;
    top: 2px;
    content: url("../../assets/plus-solid.svg");
    filter: invert(70%) sepia(90%) saturate(601%) hue-rotate(326deg)
      brightness(94%) contrast(100%);
  }

  .panel-title a.open .fa-fusion-box:before {
    content: url("../../assets/minus-solid.svg");
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .btn:hover {
    background-color: #464d5f !important;
  }
}
</style>
